@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: -2rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: -1rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

/* .greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
} */
.greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.5;
  font-family: 'Great Vibes',cursive;
  background: linear-gradient(45deg, #f3ec78, #af4261, #1e90ff, #32cd32, #ff4500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 40px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Times New Roman";
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 97px;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 46px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
  
  
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 36px;
    margin-top: 1px;
    text-align: center;
  }
  .greeting-image-div > * {
    max-width: 100%;
    height: auto;
    margin-top: 0px;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 20px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;

  }
}
