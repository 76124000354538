.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; 
}

.responsive-video {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* For desktop view */
@media (min-width: 768px) {
  .responsive-video {
    top: 20%;
    left: 10%;
  }
}

/* For mobile view */
@media (max-width: 767px) {
  .responsive-video {
    top: 0;
    left: 0;
  }
}


