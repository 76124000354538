.cert-card {
  width: 30%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #d9dbdf;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #d9dbdf;
  margin-bottom: 30px;
  flex: 0 0 30%;
  position: relative;
  overflow: hidden;
}

.logo_img {
  margin: 3px;
  width: auto;
  height: 200px;
  transition: transform 0.4s ease; /* Add transition for zoom effect */
}

.logo_img:hover {
  transform: scale(1.1); /* Apply zoom effect on hover */
}

/* Overlay styles */
.cert-card .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.4s ease; /* Add transition for overlay */
}

.cert-card:hover .content-overlay {
  opacity: 1; /* Show overlay on hover */
}

.cert-header {
  margin: 0px;
  border-radius: 5px 5px 0px 0px;
}

.cert-body {
  margin: 0px;
}

.cert-body-title {
  font-family: "Times New Roman";
  font-size: 20px;
  margin: 10px;
}

.cert-body-subtitle {
  font-family: "Times New Roman";
  font-size: 18px;
  color: #808080;
  margin: 10px;
}
@media (max-width: 768px) {
  .cert-card {
    width: 90%;
    margin: 0 auto 20px auto;
  }

  .logo_img {
    width: 100%;
    height: auto;
    margin: 0;
  }
}