.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Times New Roman";
}

.footer-div {
  margin-top: 2rem;
}
