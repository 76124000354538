.skills-main-div {
  display: flex;
  overflow: hidden;
}

.skills-header {
  font-size: 60px;
  line-height: normal;
  font-family: "Times New Roman";
  text-align: center;
}

.skills-text {
  font-size: 20px;
}

.skills-text-div {
  margin-left: 50px;
  font-family: "Times New Roman";
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 100px;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
  font-family: "Times New Roman";
  line-height: normal;
}

/* Skills.css */

.mainrequest {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mainrequest img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
    padding: 10px;
  }
}


/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
  /* .skills-image-div > img {
    margin-top: 0px;
  } */
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
    /* order: -2; */
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 22px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 0px;
  }
  .skills-text {
    font-size: 16px;
    text-align: center;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: -1;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
}
