.container {
  display: flex;
  justify-content: center;
}

/* Rectangle Card Layout */
.rectangle {
  background: url(..\..\assests\images\talksCardBack.svg);
  width: 380px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  transition: all 0.3s ease;
  margin-top: 50px;
  padding: 30px;
  box-sizing: border-box;
}
.rectangle:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}

.mask {
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute;
}

/* Card Styling */
.talk-card-title {
  color: white;
  text-align: left;
  font-size: 25px;
  line-height: normal;
  font-weight: 610;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.talk-card-subtitle {
  text-align: justify;
  font-size: 16px;
  color: #7f8287;
  line-height: 18px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.talk-button {
  background-color: #a066fb;
  color: white;
  width: 100px;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  margin: 0 auto;
}
.card-footer-button-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Form Styling */
.talk-form .form-group {
  margin-bottom: 15px;
}
.talk-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: rgb(5, 10, 61);
}
.talk-form input,
.talk-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  box-sizing: border-box;
}
.talk-form textarea {
  resize: vertical;
  height: 80px;
}

/* Media Query */
@media (max-width: 1380px) {
  .talk-card-title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .mask {
    clip-path: none;
    position: relative;
  }
  .talk-card-title {
    margin-top: 10px;
    font-family: 200;
  }
  .talk-card-image {
    width: 100%;
  }
  .square {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
}
