.certs-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Times New Roman";
  text-align: center;
}

.certs-body-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.center {
  display: flex;
  justify-content: center;
}


@media (max-width: 1380px) {
  .certs-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .certs-body-div {
    flex-direction: column;
  }

  .certs-header {
    font-size: 30px;
  }
}x
.underlined-text {
  border-bottom: 4px solid #fca326; 
  padding-bottom: 2px; 
}

.brushed-underline {
  position: relative;
  display: inline-block;
}

.brushed-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px; 
  width: 100%;
  height: 4px; 
  background: linear-gradient(
    to right,
    #c18024 10%, 
    transparent 20%,
    #c18024 30%, 
    transparent 40%,
    #c18024 50% 
  );
}
